export default class PdfJsInterop {

  #pdfData = null;

  Load = function (bytes, promiseHandler) {
    this.#pdfData = atob(bytes);
    var loadingTask = pdfjsLib.getDocument({ data: this.#pdfData });
    loadingTask.promise.then(function (pdf) {
      //this.#pdf = pdf;
      var pages = pdf.numPages;
      promiseHandler.invokeMethodAsync('SetResult', pages);
    }, function (reason) {
      // PDF loading error
      console.error(reason);
      promiseHandler.invokeMethodAsync('SetResult', 0);
    });
  }

  Render = function (element, scale, page) {
    // Using DocumentInitParameters object to load binary data.
    var loadingTask = pdfjsLib.getDocument({ data: this.#pdfData });
    loadingTask.promise.then(function (pdf) {

      // Fetch the first page
      var pageNumber = page;
      pdf.getPage(pageNumber).then(function (page) {
        var viewport = page.getViewport({ scale: scale });

        // Prepare canvas using PDF page dimensions

        var canvas = element;
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };

        var renderTask = page.render(renderContext);

        //renderTask.promise.then(function () {
        //  console.log('Page rendered');
        //});
      });
    }, function (reason) {
      // PDF loading error
      console.error(reason);
    });
  }

  Print = function (bytes) {
    var iframe = document.getElementById('pdfIframe');
    if (iframe == null) {
      iframe = document.createElement('iframe');
      iframe.id = 'pdfIframe'
    }
    iframe.className = 'pdfIframe'
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
        URL.revokeObjectURL(url)
      }, 1);
    };
    var blob = this.Base64ToBlob(bytes);
    var url = URL.createObjectURL(blob);
    iframe.src = url;
  }

  Base64ToBlob = function (b64Data, contentType = 'application/pdf', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
