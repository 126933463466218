// Import our styles
import './_wwwroot/scss/open-sans';
import './_wwwroot/scss/fontawesome';
import './_wwwroot/scss/tailwindcss';
import './**/*.razor.scss'; // add custom component classes before your utilities
import './_wwwroot/scss/default.scss';
import './_wwwroot/scss/tailwindcss/utilities.scss';

import BrowserInterop from './_wwwroot/js/BrowserInterop';
import FormInterop from './_wwwroot/js/FormInterop';
import PdfJsInterop from './_wwwroot/js/PdfJsInterop';

import { createPopper } from '@popperjs/core';

// Replace/Create the global namespace
// Grab an existing namespace object, or create a blank object if it doesn't exist
window.FormInterop = document.FormInterop = Element.prototype.FormInterop
  = window.FormInterop || document.FormInterop || Element.prototype.FormInterop || new FormInterop();

window.BrowserInterop = document.BrowserInterop = Element.prototype.BrowserInterop
  = window.BrowserInterop || document.BrowserInterop || Element.prototype.BrowserInterop || new BrowserInterop();

window.PdfJsInterop = document.PdfJsInterop = Element.prototype.PdfJsInterop
  = window.PdfJsInterop || document.PdfJsInterop || Element.prototype.PdfJsInterop || new PdfJsInterop();

window.Popper = document.Popper = Element.prototype.Popper
  = window.Popper || document.Popper || Element.prototype.Popper || function (elem, menu, param) { createPopper(elem, menu, param) };

window.pdfjsLib = document.pdfjsLib = Element.prototype.pdfjsLib
  = window.pdfjsLib || document.pdfjsLib || Element.prototype.pdfjsLib || require('pdfjs-dist/build/pdf');

window.pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');
