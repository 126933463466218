export default class FormInterop {

  Focus = function (element) {
    if (element) {
      element.focus();
    }
  }

  ClearValue = function (element) {
    if (element) {
      element.value = "";
    }
  }

}
