export default class BrowserInterop {
  #scrollTop = 0;
  #resizeObserver = null;
  #mutationObserver = null;
  #gridDropPosition;
  #gridTimerId;

  constructor() {
    // Bind "static events" of browser
    window.addEventListener("scroll", e => {
      var documentElement = document.documentElement;

      DotNet.invokeMethodAsync('Specifi.Razor.Components', 'WindowOnScroll', window.scrollX, window.scrollY);

      if (documentElement.scrollTop + documentElement.clientHeight >= documentElement.scrollHeight) {
        DotNet.invokeMethodAsync('Specifi.Razor.Components', 'WindowOnScrollAtBottom');
      }
    });

    document.addEventListener("keydown", e => {
      var args = {
        keyCode: e.which || e.keyCode,
        altKey: e.altKey,
        ctrlKey: e.ctrlKey,
        shiftKey: e.shiftKey,
        metaKey: e.metaKey,
      }
      DotNet.invokeMethodAsync('Specifi.Razor.Components', 'DocumentOnKeyDown', args);
    });

    document.addEventListener("keyup", e => {
      var args = {
        keyCode: e.which || e.keyCode,
        altKey: e.altKey,
        ctrlKey: e.ctrlKey,
        shiftKey: e.shiftKey,
        metaKey: e.metaKey,
      }
      DotNet.invokeMethodAsync('Specifi.Razor.Components', 'DocumentOnKeyUp', args);
    });

    window.gridDragImage = new Image();
    window.gridDragImage.src = '_content/Specifi.Razor.Components/row.png';
  }

  SubscribeForElementScrollToBottom = function (element, elementId) {
    if (element !== null && element !== undefined && typeof element.addEventListener === 'function') {
      window.elementScrollNotified = false;
      window.elementScrollId = elementId;
      element.addEventListener('scroll', this.OnElementScrollToBottom);
    }
  }

  UnsubscribeForElementScrollToBottom = function (element, elementId) {
    if (element !== null && element !== undefined && typeof element.removeEventListener === 'function') {
      element.removeEventListener('scroll', this.OnElementScrollToBottom);
    }
  }

  OnElementScrollToBottom = function (event) {
    let element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 1 && !window.elementScrollNotified) {
      DotNet.invokeMethodAsync('Specifi.Razor.Components', 'ElementOnScrollAtBottom', { elementId: window.elementScrollId });
      window.elementScrollNotified = true;
      setTimeout(() => window.elementScrollNotified = false, 500);
    }
  }

  AdjustDropdown = function (dropdown) {
    if (dropdown) {
      let child = dropdown.querySelector('.dropdown-content');
      if (child) {
        child.style.height = 'auto';

        let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        let childBoundingRect = child.getBoundingClientRect();
        let elemBoundingRect = dropdown.getBoundingClientRect();

        let calculatedTop = elemBoundingRect.top + dropdown.offsetHeight;
        if (calculatedTop + childBoundingRect.height > viewportHeight) {
          calculatedTop = calculatedTop - childBoundingRect.height - elemBoundingRect.height - 1;

          if (calculatedTop < 0) {
            calculatedTop = 0;
          }

          if (childBoundingRect.height > viewportHeight) {
            child.style.height = viewportHeight - calculatedTop + 'px';
          }
        }

        let calculatedLeft = elemBoundingRect.left;
        if (calculatedLeft + childBoundingRect.width > viewportWidth) {
          calculatedLeft = calculatedLeft - (calculatedLeft + childBoundingRect.width - viewportWidth) - 20;
        }

        child.style.top = calculatedTop + 'px';
        child.style.left = calculatedLeft + 'px';
        child.style.width = elemBoundingRect.right - elemBoundingRect.left + 'px';
      }
    }
  };

  OpenDropdown = function (dropdown) {
    this.#scrollTop = document.documentElement.scrollTop;
    document.documentElement.style.top = `-${this.#scrollTop}px`
    document.documentElement.classList.add('no-scroll');
    this.#resizeObserver = new ResizeObserver(entry => this.AdjustDropdown(entry[0].target));
    this.#resizeObserver.observe(dropdown);
    this.#mutationObserver = new MutationObserver((record, observer) => this.AdjustDropdown(dropdown));
    this.#mutationObserver.observe(dropdown, { childList: true, subtree: true });
    this.AdjustDropdown(dropdown);
  }

  CloseDropdown = function (dropdown) {
    document.documentElement.style.top = null;
    document.documentElement.classList.remove('no-scroll');
    document.documentElement.scrollTop = this.#scrollTop;
    this.#resizeObserver.unobserve(dropdown);
    this.#mutationObserver.disconnect();
  }

  SaveAllBytes = function (fileName, bytesAsBase64) {
    let link = document.createElement('a');
    link.download = fileName;
    link.href = "data:application/octet-stream;base64," + bytesAsBase64;
    document.body.appendChild(link); // Needed for Firefox
    link.click();
    document.body.removeChild(link);
  }

  DownloadImageWithWatermark = function (bytesAsBase64, imageName) {
    var canvas = document.createElement('canvas');
    canvas.setAttribute("hidden", "true");
    canvas.setAttribute("width", "4096px");
    canvas.setAttribute("height", "4096px");
    document.body.appendChild(canvas);

    var ctx = canvas.getContext('2d');
    var image = new Image();
    var wm = new Image();

    image.onload = function () {
      canvas.setAttribute("width", image.width);
      canvas.setAttribute("height", image.height);
      ctx.drawImage(image, 0, 0);
      var imageSize = Math.min(image.width, image.height);

      wm.onload = function () {
        ctx.drawImage(wm, 0, 0, imageSize, imageSize);

        var link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = imageName + '.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    };

    image.src = 'data:image/jpg;base64,' + bytesAsBase64;
    wm.src = '_content/Specifi.Razor.Components/watermark.png';
    document.body.removeChild(canvas);
  }

  SaveByURL = function (fileName, url) {
    let link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    document.body.appendChild(link); // Needed for Firefox
    link.click();
    document.body.removeChild(link);
  }

  GetComputedWidth = function (element) {
    if (element) {
      return window.getComputedStyle(element).width;
    }
  }

  GetComputedHeight = function (element) {
    if (element) {
      return window.getComputedStyle(element).height;
    }
  }

  SetConfirmBeforeWindowUnload = function () {
    window.onbeforeunload = function (e) {
      return true;
    };
  }

  UnsetConfirmBeforeWindowUnload = function () {
    window.onbeforeunload = null;
  }

  HasConfirmBeforeWindowUnload = function () {
    return window.onbeforeunload !== null;
  }

  RiseConfirm = function (msg) {
    var cnf = confirm(msg);
    return cnf;
  }

  StartResize = function (gridId, canResizeTable) {
    let th;
    let table;
    let thWidth;
    let tableWidth;
    let startPosition;

    th = event.target.parentElement;
    thWidth = parseInt(getComputedStyle(th).width);
    table = th.parentElement.parentElement.parentElement;
    tableWidth = parseInt(getComputedStyle(table).width);
    startPosition = event.clientX;
    document.addEventListener("mousemove", Resize);
    document.addEventListener("mouseup", EndResize);

    function Resize() {
      if (th) {
        let position = event.clientX;
        let distance = position - startPosition;
        let width = thWidth + distance;
        if (width >= (parseInt(th.style.minWidth) | 0)) {
          th.style.width = `${width}px`;
          if (canResizeTable) {
            table.style.width = `${tableWidth + distance}px`;
          }

          let thElems = table.querySelectorAll(`.child-grid th[data-column-order="${th.dataset.columnOrder}"]`)
          for (let thElem of thElems) {
            thElem.style.width = `${width}px`;
          }
        }

        event.preventDefault();
      }
    }

    function EndResize() {
      document.removeEventListener("mousemove", Resize);
      document.removeEventListener("mouseup", EndResize);

      let args = {
        gridId: gridId,
        columnNumber: parseInt(th.dataset.columnOrder),
        columnWidth: getComputedStyle(th).width,
        tableWidth: getComputedStyle(table).width
      };
      DotNet.invokeMethodAsync('Specifi.Razor.Components', 'GridResizeEnd', args);
    }
  }

  DragOverGridRow = function (allowDrop) {
    if (!this.#gridTimerId) {

      let targetTr = event.currentTarget;
      let tableBody = targetTr.parentElement;
      let trHeight = parseInt(this.GetComputedHeight(targetTr));

      for (let tr of tableBody.querySelectorAll('tr')) {
        tr.classList.remove('drop', 'drop-up', 'drop-down', 'drop-over')
      }

      if (!allowDrop) {
        return;
      }

      if (event.offsetY <= 6) {
        targetTr.classList.add('drop', 'drop-up');
        this.#gridDropPosition = 0; // Specifi.Razor.Components.Grids.DropPosition.Above
      }
      else if (event.offsetY >= trHeight - 6) {
        targetTr.classList.add('drop', 'drop-down');
        this.#gridDropPosition = 1; // Specifi.Razor.Components.Grids.DropPosition.Below
      }
      else {
        targetTr.classList.add('drop', 'drop-over');
        this.#gridDropPosition = 2; // Specifi.Razor.Components.Grids.DropPosition.Over
      }

      this.#gridTimerId = setTimeout(() => {
        this.#gridTimerId = undefined;
      }, 50);
    }

    event.preventDefault();
  }

  DropGridRow = function (gridElement) {
    for (let tr of gridElement.querySelectorAll('tr')) {
      tr.classList.remove('drop', 'drop-up', 'drop-down', 'drop-over')
    }

    return this.#gridDropPosition;
  }

  ScrollElement = function (element, top, left) {
    element.scroll({ top: top, left: left })
  }

  GetCanvasImageBytes = function (selector) {
    let canvas = document.querySelector(selector);
    let dataURL = canvas.toDataURL();
    return dataURL.replace(/^data:image\/?[A-z]*;base64,/, '');
  }
}
